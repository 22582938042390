import React from "react"

interface SelectProps {
  options: string[]
  value: string
  onChange: (value: string) => void
}

export function Select(props: SelectProps) {
  return (
    <select value={props.value} onChange={(event) => props.onChange(event.target.value)}>
      {props.options.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </select>
  )
}

interface ButtonProps {
  text: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export function Button(props: ButtonProps) {
  return (
    <button onClick={props.onClick ? props.onClick : undefined} disabled={!props.onClick}>
      {props.text}
    </button>
  )
}
