import { useState } from "react"

export function usePersistedState<Value, Key extends string>(
  defaultValue: Value,
  key: Key,
): [Value, (value: Value) => void] {
  const [value, setValue_] = useState<Value>(() => {
    const rawItem = window.localStorage.getItem(key)
    return rawItem ? JSON.parse(rawItem) : defaultValue
  })

  function setValue(newValue: Value) {
    setValue_(newValue)
    window.localStorage.setItem(key, JSON.stringify(newValue))
  }

  return [value, setValue]
}
