import { useState } from "react"
import { useGraphQLEditorContent } from "./useGraphQLEditorContent"
import { ToolbarButton } from "@graphiql/react"
import { ArrowUpOnSquareIcon } from "./ArrowUpOnSquareIcon"
import React from "react"
import { CopyPlaygroundDialog } from "./CopyPlaygroundDialog"

export const CopyPlaygroundToolbarButton = () => {
  const editorContent = useGraphQLEditorContent()

  const [isCopyPlaygroundDialogOpen, setIsCopyPlaygroundDialogOpen] = useState(false)
  return (
    <>
      <ToolbarButton onClick={() => setIsCopyPlaygroundDialogOpen(true)} label="Share query">
        <ArrowUpOnSquareIcon />
      </ToolbarButton>
      <CopyPlaygroundDialog
        isOpen={isCopyPlaygroundDialogOpen}
        onClose={() => setIsCopyPlaygroundDialogOpen(false)}
        editorContent={editorContent}
      />
    </>
  )
}
