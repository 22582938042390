export interface Schema {
  name: string
  url: string
}

export const app: Schema = {
  name: "app",
  url: "api-gw-hive-customerapp",
}

export const backoffice: Schema = {
  name: "backoffice",
  url: "api-gw-hive-backoffice",
}

export const infleeting: Schema = {
  name: "infleeting",
  url: "api-gw-hive-infleetingapp",
}

export const fieldapp: Schema = {
  name: "fieldapp",
  url: "api-gw-hive-fieldapp",
}

export const organizationportal: Schema = {
  name: "organizationportal",
  url: "api-gw-hive-organizationportal",
}

export const all = [app, backoffice, infleeting, fieldapp, organizationportal]

export function fromName(name: string) {
  switch (name) {
    case "app":
      return app
    case "backoffice":
      return backoffice
    case "infleeting":
      return infleeting
    case "fieldapp":
      return fieldapp
    case "organizationportal":
      return organizationportal
    default:
      throw new Error(`No Schema for string '${name}'`)
  }
}
