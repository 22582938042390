import React from "react"
import { Button, Dialog } from "@graphiql/react"
import { useRef } from "react"
import { useEvent } from "./useEvent"
import { editorContentToUrlFragment } from "./useGraphQLEditorContent"
import { EditorContent } from "./types"

interface CopyPlaygroundDialogProps {
  editorContent: EditorContent
  isOpen: boolean
  onClose: () => void
}

export const CopyPlaygroundDialog = ({ editorContent, isOpen, onClose }: CopyPlaygroundDialogProps) => {
  const urlElRef = useRef<HTMLInputElement>(null)

  const editorContentUrl = new URL(window.location.toString())
  editorContentUrl.hash = editorContentToUrlFragment(editorContent)

  const copyURLToClipboard = useEvent(() => {
    if (!urlElRef.current) {
      return
    }

    urlElRef.current.select()
    document.execCommand("copy")
  })

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && onClose()}>
      <div className="graphiql-share-link-dialog">
        <div className="graphiql-dialog-header">
          <div className="graphiql-dialog-title">Share query</div>
          <Dialog.Close onClick={onClose} />
        </div>
        <div className="graphiql-dialog-section">
          <div>
            <div className="graphiql-dialog-section-title">Copy query URL</div>
            <div className="graphiql-dialog-section-caption">
              Includes queries and variables of each tab.
              <br />
              <i>(Sharing will not include selected schema.)</i>
              <br />
              <br />
            </div>
            <div className="graphiql-dialog-section-caption graphiql-copy-button-section">
              <input
                aria-label="Query URL"
                className="graphiql-button url-input"
                readOnly
                value={editorContentUrl.toString()}
                ref={urlElRef}
              />
              <Button
                type="button"
                onClick={(e) => {
                  e.preventDefault()
                  copyURLToClipboard()
                }}
              >
                Copy URL
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
