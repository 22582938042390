export interface Environment {
  name: string
  url: string
  studioUrl: string
  firebase: {
    apiKey: string
    authDomain: string
    databaseURL: string
    storageBucket: string
  }
}

export const production: Environment = {
  name: "production",
  url: ".vcm.sh",
  studioUrl: "https://studio-token.vcm.sh",
  firebase: {
    apiKey: "AIzaSyAf7prkqnH9XlJZM04uaPsn1AwnY9ODpUw",
    authDomain: "auth.vcm.sh",
    databaseURL: "https://vcm-production-firebase.firebaseio.com",
    storageBucket: "vcm-production-firebase.appspot.com",
  },
}

export const staging: Environment = {
  name: "staging",
  url: ".vcm-staging.sh",
  studioUrl: "https://studio-token.vcm-staging.sh",
  firebase: {
    apiKey: "AIzaSyD2MBaDguwDxLBRZaqRjSNA6kOpJhiNlzE",
    authDomain: "auth.vcm-staging.sh",
    databaseURL: "https://vcm-staging-firebase.firebaseio.com",
    storageBucket: "vcm-staging-firebase.appspot.com",
  },
}

export const all = [production, staging]

export function fromName(name: string) {
  switch (name) {
    case "production":
      return production
    case "staging":
      return staging
    default:
      throw new Error(`No Environment for string '${name}'`)
  }
}
